import { Component, OnInit, HostListener, Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css'],
})
@Injectable()
export class LandingComponent implements OnInit {
  constructor(private router: Router) {}
  mobile = window.screen.width < 540;
  top = 0;
  busy = false;
  count = 0;
  showContent = true;
  sections = [
    {
      heading: 'About Us',
      description:
        'Antaragni is the annual cultural festival of IIT Kanpur and one of the biggest across Asia. With a legacy of 55 years, Antaragni entertains a footfall of over 130,000 to provide a highly competitive national level platform and host concerts of artists with international repute.',
      link: 'about',
      background: '/assets/bgs/about.jpg',
    },
    {
      heading: 'Brochure',
      description:
        'The bequest of Antaragni has seen various memorable moments across the years. It is the consolidation of fierce competition, astonishing performances and energy like no other. Have a look at our brochure to get a glimpse of our 55 year legacy.',
      link: 'brochure',
      background: '/assets/bgs/Brochure.jpg',
    },
    // {
    //   heading: 'Schedule',
    //   description:
    //     "Save the dates for the most amazing virtual festival of the year! Here is the much-awaited final schedule for Antaragni '21, spread across three fun-filled days.",
    //   link: 'schedule',
    //   background: '/assets/bgs/schedule.jpg',
    // },
    {
      heading: 'Influencers',
      description:
        "Antaragni is always about unforetold tales and mysteries. The Antaragni Influencer Program is another milestone in bringing forth new prosperous relationships and letting the phoenix spread its wing.",
      link: 'https://influencers.antaragni.in/',
      background: '/assets/bgs/influencers.jpg',
      redirect:true
    },
    {
      heading: 'Past Sponsors',
      description:
        "Antarangni's grandeur has been established on account of the confidence and backing we receive from our ever-supportive sponsors. Our sponsors help us test and conquer new heights each year.",
      link: 'sponsors',
      background: '/assets/bgs/sponsors.jpg',
    },
    //{
    //heading: 'Past Lineup',
    //description:
    //  'Antaragni’s stage in the past has witnessed epic performances by various artists across different genres. From top EDM giants like KSHMR to Rock bands like Skyharbor to Indian superstars like Shankar-Ehsaan-Loy, all have taken up our stage.',
    //link: 'lineup',
    //background: '/assets/bgs/brochure.jfif',
    //},
    // {
    //   heading: 'Star Attractions',
    //   description:
    //     'Antaragni \'21 is star-studded as always, featuring top names from across industries. Have a look at the jaw-dropping lineup of artists, comedians, politicians, industrialists and much more!',
    //   link: 'star-attraction',
    //   background: '/assets/bgs/star-attraction.gif',
    // },
    {
      heading: 'Media',
      description:
        'The grandeur of our fest has featured in the top media outlets of the country, across print, radio and TV. Here are some top highlights from over the years, with more feathers constantly being added to the hat!',
      link: 'media',
      background: '/assets/bgs/media.jpg',
    },
    {
      heading: 'The Team',
      description:
        'The glory of Antaragni is all possible because of its persevering, determined and spirited team.',
      link: 'team',
      background: '/assets/bgs/team.jpg',
    },
    {
      heading: 'Aftermovie',
      description:
        "Before the rise of glorious fire, refresh your memories and relive the blazes ignited in the past. Take a look at the aftermovie of Antaragni '18.",
      link: 'aftermovie',
      background: '/assets/bgs/aftermovie.jpeg',
    },
    // {
    // heading: 'Virtual Concert',
    // description:
    // 'It will be an immersive digital experience for music lovers and will feature some upcoming releases, but above all it is an ode to our first step into the future that is virtual entertainment.',
    // link: 'vc',
    // background: '/assets/bgs/edm.jpg',
    // },
    // {
    // heading: 'Prodigy',
    // description: 'Offering a platform to young school kids to showcase their talent and make sure that it doesn’t go unnoticed has been the motto of Prodigy. So we’ve come up with a number of interesting online competitions, which school students can participate in, from their home, to carry on the brand name of Antaragni and the legacy we have set.',
    // link: 'prodigy',
    // background: '/assets/bgs/prodigy.jpg'
    // }
  ];

  ngOnInit(): void {
    this.showContent = true;
    this.busy = false;
  }

  next() {
    if (this.mobile) {
      document.getElementById('section0').scrollIntoView({
        behavior: 'smooth',
      });
      return;
    }
    if (!this.busy && this.count < this.sections.length) {
      this.count++;
      this.busy = true;
      this.top -= 100;
      setTimeout(() => {
        this.busy = false;
      }, 800);
    }
  }

  back() {
    if (!this.busy && this.count > 0) {
      this.count--;
      this.busy = true;
      this.top += 100;
      setTimeout(() => {
        this.busy = false;
      }, 800);
    }
  }

  goToTop() {
    if (!this.busy) {
      this.count = 0;
      this.busy = true;
      this.top = 0;
      setTimeout(() => {
        this.busy = false;
      }, 800);
    }
  }

  go(i) {
    if(this.sections[i].redirect === true){
      window.open(this.sections[i].link, '_blank');
      return;
    }
    if (this.mobile) {
      
      this.router.navigate(['/' + this.sections[i].link]);
      return;
    }
    this.busy = true;
    this.showContent = false;
    if (i % 2) {
      setTimeout(() => {
        document.getElementById('overlay' + i).style.left = 100 + 'vw';
      }, 200);
    } else {
      setTimeout(() => {
        document.getElementById('overlay' + i).style.left = -50 + 'vw';
      }, 200);
    }
    setTimeout(() => this.router.navigate(['/' + this.sections[i].link]), 800);
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(
    event: KeyboardEvent
  ) {
    if (event.key === 'ArrowDown') {
      this.next();
    } else if (event.key === 'ArrowUp') {
      this.back();
    }
  }
}
