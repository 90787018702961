<div class="wrapper">
<div
  class="card"
  fxLayoutAlign="center center"
  *ngFor="let contact of contacts"
  fxLayout="column"
>
  <div class="name">
    <b>{{ contact.name }}</b>
  </div>
  <div class="post">
    {{ contact.post }}
  </div>
  <div class="email"><i class="fa fa-phone"></i>+91 {{ contact.number }}</div>
  <div class="email">
    <a [href]="'mailto:' + contact.email">
      <i class="fa fa-envelope"></i> {{ contact.email }}</a
    >
  </div>
  <div class="links" fxLayout="row" fxLayoutAlign="space-around">
    <a [href]="contact.insta" target="_blank" rel="noopener">
      <i class="fa fa-instagram"></i>
    </a>
    <!--a [href]="contact.linkedin" target="_blank" rel="noopener">
      <i class="fa fa-linkedin"></i>
    </a-->
  </div>
</div>
</div>
