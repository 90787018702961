import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingComponent } from './components/landing/landing.component';
import { AboutComponent } from './components/about/about.component';
//import { LineupComponent } from './components/lineup/lineup.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { InfluencersComponent } from './components/influencers/influencers.component';  
import { CaComponent } from './components/Campus Ambassadors/ca/ca.component';
import { SponsorsComponent } from './components/sponsors/sponsors.component';
import { AftermovieComponent } from './components/aftermovie/aftermovie.component';
import { BrochureComponent } from './components/brochure/brochure.component';
import { AssociateComponent } from './components/associate/associate.component';

import { EnquiryComponent } from './components/enquiry/enquiry.component';
import { MerchandiseComponent } from './components/merchandise/merchandise.component';
import { MediaComponent } from './components/media/media.component';
import { StarAttractionComponent } from './components/star-attraction/star-attraction.component';
import { ScheduleComponent } from './components/schedule/schedule.component';
const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
  },
  {
    path: 'about',
    component: AboutComponent,
  },
  //{
    //path: 'lineup',
    //component: LineupComponent,
  //},
  {
    path: 'team',
    component: ContactsComponent,
  },
  {
    path: 'influencers',
    component: InfluencersComponent,
  },
  {
    path: 'ca',
    component: CaComponent,
  },
  {
    path: 'sponsors',
    component: SponsorsComponent,
  },
  {
    path: 'aftermovie',
    component: AftermovieComponent,
  },
  {
    path: 'associate',
    component: AssociateComponent
  },
  {
    path: 'enquiry',
    component: EnquiryComponent
  },
  {
    path: 'merchandise',
    component: MerchandiseComponent
  },
  {
    path: 'media',
    component: MediaComponent
  },
  // {
  //   path: 'schedule',
  //   component: ScheduleComponent,
  // },
  {
    path: 'star-attraction',
    component: StarAttractionComponent,
  },
  // {
    // path: 'online',
    // component: OnlineedmComponent
  // },
  // {
    // path: 'prod',
    // component: ProdigyComponent
  // },
  { path: 'brochure', redirectTo: '/brochure/1', pathMatch: 'full' },
  { path: 'brochure/:id', component: BrochureComponent },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
exports: [RouterModule],
})
export class AppRoutingModule {}
