<div class="wrapper" fxLayout="column" fxLayoutAlign="start center">
  <h1 class="heading"><span class="head">SCHE</span>DULE</h1>
  <div class="select">
    <mat-form-field appearance="fill">
      <mat-label>Categories</mat-label>
      <mat-select (selectionChange)="filterSchedules()" [(ngModel)]="filter">
        <mat-option *ngFor="let category of categories" [value]="category">
          {{ category }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <mat-accordion>
    <mat-expansion-panel *ngFor="let i of [1, 2, 3]">
      <mat-expansion-panel-header
        ><mat-panel-title>{{
          18 + i + "th March"
        }}</mat-panel-title></mat-expansion-panel-header
      >
      <table>
        <div
          *ngFor="let y of activeSchedule[i]"
          fxLayout="row"
          fxLayoutAlign="center center"
        >
          <tr>
            <td>
              {{ y.time }}
            </td>
            <td>
              {{ y.title }}
            </td>
          </tr>
        </div>
      </table>
    </mat-expansion-panel>
  </mat-accordion>
</div>
