<div class="wrapper">
    <div class="content" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="6vh" fxLayoutGap.xs="2vh">
        <h1 class="heading"><span class="head">Spon</span>sors</h1>
        <!--<div class="images" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="2vh">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>Marketing Sponsors</mat-panel-title>
                </mat-expansion-panel-header>
                <img src="/assets/sponsors/event.png">
                <img src="/assets/sponsors/mnp.png">
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>Media Partners</mat-panel-title>
                </mat-expansion-panel-header>
                <img src="/assets/sponsors/sponsors1.png">
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>Event Associates</mat-panel-title>
                </mat-expansion-panel-header>
                <img src="/assets/sponsors/sponsors2.png">
            </mat-expansion-panel>
        </div>-->
        <div class="innerWrapper" fxLayout="row warp" fxLayoutAlign="center center" *ngIf="sponsors">
          <a class="sponsorBox" *ngFor="let sponsor of sponsors" fxLayout="column" fxLayoutAlign="start center" [href]="sponsor.link" target="_blank">
            <span class="title">{{ sponsor.post }}</span>
            <div class="box" fxLayout="column" fxLayoutAlign="center center">
            <div class="imagebox">
              <img [src]="sponsor.downloadURL">
            </div>
            <div class="shadow1"></div>
            <div class="shadow2"></div>
          </div>
          </a>
        </div>
    </div>
</div>
