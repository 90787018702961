import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './influencers.component.html',
  styleUrls: ['./influencers.component.css']
})
export class InfluencersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  goToInflucencers(){
    window.open('https://influencers.antaragni.in/', '_blank');
  }

}
