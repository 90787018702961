import { Directive, Output, EventEmitter, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[appMouseWheel]',
})
export class MouseWheelDirective {
  @Output() mouseWheelUp = new EventEmitter();
  @Output() mouseWheelDown = new EventEmitter();

  constructor(private router: Router) {}

  @HostListener('mousewheel', ['$event']) onMouseWheelChrome(event: any) {
    this.mouseWheelFunc(event);
  }

  @HostListener('DOMMouseScroll', ['$event']) onMouseWheelFirefox(event: any) {
    this.mouseWheelFunc(event);
  }

  @HostListener('onmousewheel', ['$event']) onMouseWheelIE(event: any) {
    this.mouseWheelFunc(event);
  }

  mouseWheelFunc(event: any) {
    // if (this.ui.mobile || this.router.url.split('/').length > 2) {
    // return;
    // }
    // let event = window.event || event; // old IE support
    // let delta = Math.max(-1, Math.min(1, (event.wheelDelta || -event.detail)));
    if (event.wheelDelta > 0) {
      this.mouseWheelUp.emit(event);
    } else if (event.wheelDelta < 0) {
      this.mouseWheelDown.emit(event);
    }
  }
}
