import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class AresService {
  getcol = (col: string) => this.afs.doc(`_contents_/${col}`).valueChanges();

  constructor(private afs: AngularFirestore) {}
}
