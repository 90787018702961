import { Component, OnInit } from '@angular/core';
import { AresService } from 'src/app/service/ares.service.service';

@Component({
  selector: 'app-sponsors',
  templateUrl: './sponsors.component.html',
  styleUrls: ['./sponsors.component.css'],
})
export class SponsorsComponent implements OnInit {
  sponsors: Array<any>;
  constructor(private ares: AresService) {}

  ngOnInit(): void {
    this.fetchData();
  }

  fetchData(): void {
    this.ares.getcol('sponsors').subscribe((res: any) => {
      this.sponsors = res.data;
    });
  }
}
