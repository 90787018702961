import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ca',
  templateUrl: './ca.component.html',
  styleUrls: ['./ca.component.css']
})
export class CaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  goToCa(){
    window.open('https://ca.antaragni.in/', '_blank');
  }

}
