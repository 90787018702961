<div class="wrapper">
<div fxLayout="column" fxLayoutAlign="center center" class="py-5 downed background">
    <div class="form-container">
        <form fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="2vh" (ngSubmit)="submit()">
            <h1 class="heading">Associate With Us</h1>
            <mat-form-field name="cell" required>
                <mat-select placeholder="Select cell to associate with" multiple="false" [required]="true" [(value)]="selectedValue">
                    <mat-option *ngFor="let cell of cells" [value]="cell">
                        {{cell}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <input matInput [(ngModel)]="answer.company" type="text" name="company" placeholder="Company Name" #company>
            </mat-form-field>
            <mat-form-field>
                <input matInput [(ngModel)]="answer.name" type="text" matInput name="name" required placeholder="Name" #name>
            </mat-form-field>
            <mat-form-field>
                <input matInput type="email" [(ngModel)]="answer.email" matInput name="email" required placeholder="Email" #email>
            </mat-form-field>
            <mat-form-field>
                <input matInput type="number" [(ngModel)]="answer.phone" matInput name="number" required placeholder="Phone No." minLength="10" maxLength="10" #number>
            </mat-form-field>
            <mat-form-field>
                <textarea matInput name="message" [(ngModel)]="answer.message" matInput placeholder="Message" matTextareaAutosize matAutosizeMinRows="3" matAutosizeMaxRows="5" #message></textarea>
            </mat-form-field>
            <button mat-raised-button type="submit" [disabled]="submitted">
        Submit
      </button>
        </form>
    </div>
</div>
</div>
