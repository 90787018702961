import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-associate',
  templateUrl: './associate.component.html',
  styleUrls: ['./associate.component.css'],
})
export class AssociateComponent implements OnInit {
  submitted = false;
  cells;
  map: any = {
    'Public Relations': 'pr',
    Marketing: 'marketing',
    'Media & Publicity': 'mnp',
    'Events & Competitions': 'events',
    'Show Management': 'showm',
  };
  answer = {
    name: '',
    company: '',
    email: '',
    phone: '',
    message: '',
    cell: '',
  };
  selectedValue;
  constructor(
    private afs: AngularFirestore,
    private snackbar: MatSnackBar,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.cells = Object.keys(this.map);
  }

  submit() {
    if (
      this.answer.name.length === 0 ||
      this.answer.email.length === 0 ||
      this.answer.phone.length === 0 ||
      !this.selectedValue
    ) {
      this.snackbar.open('Please fill all the required fields', '', {
        duration: 1500,
      });
      return;
    }
    if (this.answer.phone.toString().length !== 10) {
      this.snackbar.open('Phone no. should have 10 digits', '', {
        duration: 1500,
      });
      return;
    }
    this.submitted = true;
    this.answer.cell = this.map[this.selectedValue];
    this.afs
      .collection('associates')
      .add(this.answer)
      .then(() => {
        this.snackbar.open('Submitted Successfully', '', {
          duration: 1500,
        });
        this.router.navigate(['/']);
      });
  }
}
