<div class="wrapper" fxLayout="column" fxLayoutAlign="start center">
  <h1 class="heading"><span class="head">STAR</span> ATTRACTIONS</h1>
  <!-- <div class="select"> -->
  <!-- <mat-form-field appearance="fill"> -->
  <!-- <mat-label>Category</mat-label> -->
  <!-- <mat-select -->
  <!-- (selectionChange)="filterAts()" -->
  <!-- [(ngModel)]="filter" -->
  <!-- name="filter" -->
  <!-- > -->
  <!-- <mat-option *ngFor="let food of categories" [value]="food"> -->
  <!-- {{ food }} -->
  <!-- </mat-option> -->
  <!-- </mat-select> -->
  <!-- </mat-form-field> -->
  <!-- </div> -->
  <div *ngFor="let cat of categories" fxLayout="column" fxLayoutAlign="center center">
    <h2 class="cat">{{ cat | titlecase }}</h2>
    <div
      class="block"
      fxLayout="row wrap"
      fxLayoutAlign="center center"
      *ngIf="attraction"
    >
      <div class="block-content" *ngFor="let attract of getCards(cat)">
        <div class="boxFront">
          <img [src]="attract.img" />
        </div>
        <div class="boxBack" fxLayout="column" fxLayoutAlign="center center">
          <p class="inner" [innerHTML]="attract.content.content"></p>
        </div>
      </div>
    </div>
  </div>
</div>
