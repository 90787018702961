import { Component, OnInit } from '@angular/core';
import { AresService } from '../../service/ares.service.service';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css'],
})
export class ScheduleComponent implements OnInit {
  categories = new Set();
  schedule = { '1': [], '2': [], '3': [] };
  activeSchedule = { '1': [], '2': [], '3': [] };
  filter: string;
  constructor(private ares: AresService) {}

  ngOnInit(): void {
    this.ares.getcol('schedule').subscribe((res: any) => {
      res.data.forEach((event) => {
        this.schedule[event.day].push(event);
        this.categories.add(event.category);
      });
      this.filterSchedules();
    });
  }

  filterSchedules(): void {
    if (this.filter) {
      for (const i of ['1', '2', '3']) {
        this.activeSchedule[i] = this.schedule[i].filter(
          (event) => event.category === this.filter
        );
      }
    } else {
      this.activeSchedule = this.schedule;
    }
  }
}
