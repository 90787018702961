
import { Injectable } from '@angular/core';

@Injectable()
export class ModernizrService {

  webpSupport: Promise<boolean>;

  constructor() {
     this.webpSupport = new Promise((resolve, reject) => {
       if (typeof window === 'undefined') {
         resolve(false);
         return;
       }
       Modernizr.on('webp', (result) => {
         if (result) {
           resolve(true);
         } else {
           resolve(false);
         }
       });
     });
  }

}
