<div class="back" routerLink="/" fxLayout="row" fxLayoutAlign="center center">
  Back
</div>

<div
  class="associate"
  fxLayout="row"
  fxLayoutAlign="center center"
  routerLink="/associate"
>
  <div>Associate With Us</div>
</div>

<div *ngIf="page && page !== '1'" class="icon left" (click)="before()">
  <span class="chevron left"></span>
</div>
<div *ngIf="page && page !== '46'" class="icon right" (click)="after()">
  <span class="chevron right"></span>
</div>
<div
  appMouseWheel
  (mouseWheelUp)="before()"
  (mouseWheelDown)="after()"
  *ngIf="page"
  class="full"
  (window:keyup)="onKey($event)"
  (swipeleft)="after()"
  (swiperight)="before()"
  [style.background-image]="backgroundImage | async"
></div>

<div
  class="bottom"
  [ngStyle]="{ left: left + 'px' }"
  fxLayout="row"
  fxLayoutAlign="center center"
>
  <div
    class="buttons"
    fxLayout="row"
    fxLayoutAlign="center center"
    fxLayoutAlign.xs="start center"
  >
    <div *ngFor="let page of pages; let i = index" class="cover">
      <a
        [routerLink]="['/brochure', i + 1]"
        class="bottom-link"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        {{ i + 1 }}
      </a>
    </div>
  </div>
</div>
