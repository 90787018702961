import { Component, OnInit, HostListener } from '@angular/core';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
// import * as _ from 'lodash';
import { values, groupBy } from 'lodash';
@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css'],
})
export class ContactsComponent implements OnInit {
  mobile = window.screen.width < 540;
  busy = false;
  top = 0;
  count = 0;
  mapping = [
    'Events & Competitions',
    'Finance',
    'Marketing',
    'Media & Publicity',
    'Public Relations',
    'Security',
    'Show Management',
    'Design',
    'Web & App',
    'Festival Coordinators',
  ];
  contacts = of([ 
    {
      name: 'Pranay Agrawal',
      post: 'Head, Events & Competitions',
      image:
          'https://drive.google.com/uc?export=view&id=1Vbt5pLcEFZZrLwJ9lw5hg3FYAkTWMkwe',
      fb_userid: 'https://www.facebook.com/pranay.agrawal.7906',
      phone: '+919315566078',
      gmail: 'pranayagrawal6@gmail.com',
      ant_userid: 'pranay',
      linkedin: 'https://www.linkedin.com/in/pranay-agrawal-b147701a7/',
      row: 1,
    },
    {
      name: 'Dipak Miglani',
      post: 'Head, Events & Competitions',
      image:
          'https://drive.google.com/uc?export=view&id=1v32YIT1Ruz18I7OdDYkGi1hMTf8EWESI',
      fb_userid: 'https://www.facebook.com/dipak.miglani.79/',
      phone: '+917982498759',
      gmail: 'dipakmiglani5@gmail.com',
      ant_userid: 'dipakm',
      linkedin: 'https://www.linkedin.com/in/dipak-miglani-207826166',
      row: 1,
    },
    {
      name: 'Himanshu Patidar',
      post: 'Head, Events & Competitions',
      image:
          'https://drive.google.com/uc?export=view&id=19zrDjouKv0qZ2BlGCaODJrZtyAudGKfH',
      fb_userid: 'https://www.facebook.com/himu.patidar.11',
      phone: '+919009507417',
      gmail: 'patidarhimanshu11@gmail.com',
      ant_userid: 'himanshu',
      linkedin: 'https://www.linkedin.com/in/himanshu-patidar-8785001a9',
      row: 1,
    },
    {
      name: 'Karanveer Singh',
      post: 'Head, Finance',
      image:
          'https://drive.google.com/uc?export=view&id=1rW0JfZR2gJPpXEomErM7LUH14vps_ABn',
      fb_userid: 'https://www.facebook.com/karanveer.singh28',
      phone: '+917985411926',
      gmail: 'skaranveer28@gmail.com',
      ant_userid: 'karan',
      linkedin: 'https://www.linkedin.com/in/karanveer-singh-58924a157',
      row: 2,
    },
    {
      name: 'Ashutosh Bajpai',
      post: 'Head, Marketing',
      image:
          'https://drive.google.com/uc?export=view&id=14ChBd7DicWfaTIPNHSQtkp-Og0Y-7tl5',
      fb_userid: 'https://www.facebook.com/ashutosh.bajpai.31149',
      phone: '+919140608926',
      gmail: 'ashubajpai54@gmail.com',
      ant_userid: 'ashutosh',
      linkedin: 'https://www.linkedin.com/in/ashutoshbajpai54',
      row: 3,
    },
    {
      name: 'Bhavan Soni',
      post: 'Head, Marketing',
      image:
          'https://drive.google.com/uc?export=view&id=1PamfF5S02KLu1-sMKhton-a4YKuz2ITd',
      fb_userid: 'https://www.facebook.com/bhavan.soni.5201/',
      phone: '+917688858123',
      gmail: 'bhavansoni2000@gmail.com',
      ant_userid: 'bhavan',
      linkedin: 'https://linkedin.com/in/bhavan-soni-584130185',
      row: 3,
    },
    {
      name: 'Kartik Gupta',
      post: 'Head, Marketing',
      image:
          'https://drive.google.com/uc?export=view&id=1dE4Qtgxb7SQRuO3QvdHHlNAGRzj9gUJu',
      fb_userid: 'https://www.facebook.com/kartik.gupta.10888',
      phone: '+919711585548',
      gmail: 'guptakartik842@gmail.com',
      ant_userid: 'kartik',
      linkedin: 'https://www.linkedin.com/in/kartik-gupta-0ba949195',
      row: 3,
    },
    {
      name: 'Aman Mishra',
      post: 'Head, Media & Publicity',
      image:
          'https://drive.google.com/uc?export=view&id=1QnUDteLVxGrcNdZr2a-yMb8VHepJR02Y',
      fb_userid: 'https://www.facebook.com/amanmishra012',
      phone: '+916377566437',
      gmail: 'a.mishr.man@gmail.com',
      ant_userid: 'aman',
      linkedin: 'http://www.linkedin.com/in/amanmishra012',
      row: 4,
    },
    {
      name: 'Harshit Sinha',
      post: 'Head, Media & Publicity',
      image:
          'https://drive.google.com/uc?export=view&id=1zqLYljnRN8SrZ5TpmVAbhTjU5yibfZRe',
      fb_userid: 'https://www.facebook.com/hsinha2000',
      phone: '+919820447889',
      gmail: 'hsinha2000@gmail.com',
      ant_userid: 'hsinha',
      linkedin: 'https://www.linkedin.com/in/hsinha47',
      row: 4,
    },
    {
      name: 'Shahil kumar Singh',
      post: 'Head, Public Relations',
      image:
          'https://drive.google.com/uc?export=view&id=1sEJOZvnu2bqAqUKez_nrvaaZXH6U4zmR',
      fb_userid: 'https://www.facebook.com/shahilkr.singh',
      phone: '+919523719469',
      gmail: 'shahilkumar.singh9523@gmail.com',
      ant_userid: 'shahil',
      linkedin: 'https://www.linkedin.com/in/shahil-singh-2636221b0',
      row: 5,
    },
    {
      name: 'Aryaman Shandilya',
      post: 'Head, Public Relations',
      image:
          'https://drive.google.com/uc?export=view&id=1RBHnamgJbB_GriGY1MzAmQ48hhl1kifu',
      fb_userid: 'https://www.facebook.com/shandy42',
      phone: '+918587802910',
      gmail: 'shandilya105@gmail.com',
      ant_userid: 'shandilya',
      linkedin: 'https://www.linkedin.com/in/aryaman-shandilya',
      row: 5,
    },
    {
      name: 'Manish Choudhary',
      post: 'Head, Security',
      image:
          'https://drive.google.com/uc?export=view&id=1tlrEwwfkvL3YQo_rUJN4helI_QO3an1g',
      fb_userid: 'https://www.facebook.com/manishfunky.choudhary',
      phone: '+919559089656',
      gmail: 'manishfunky07@gmail.com',
      ant_userid: 'manish',
      linkedin: 'https://www.linkedin.com/in/manish-07',
      row: 6,
    },
    {
      name: 'Aakash Soni',
      post: 'Head, Security',
      image:
          'https://drive.google.com/uc?export=view&id=15qfEqEjo1DoE5gKVKugSw2xjMxmc5yU_',
      fb_userid: 'https://www.facebook.com/aakash.soni.7543/',
      phone: '+917737210896',
      gmail: 'aakash.301999@gmail.com',
      ant_userid: 'aakaso',
      linkedin: 'https://www.linkedin.com/in/aakash-soni-826646193/',
      row: 6,
    },
    {
      name: 'Mahesh Kumar Prajapat',
      post: 'Head, Show Management',
      image: 'https://drive.google.com/uc?export=view&id=1GiCDIgH7i8xvOkquJ-aADT_ULaX--tYW',
      fb_userid: 'https://www.facebook.com/mahesh.prajapat.319',
      phone: '+919783381150',
      gmail: 'maheshprajapat4455@gmail.com',
      ant_userid: 'mahesh',
      linkedin: 'https://www.linkedin.com/in/mahesh-kumar-prajapat-',
      row: 7,
    },
    {
      name: 'Naman Jain',
      post: 'Head, Show Management',
      image: 'https://drive.google.com/uc?export=view&id=1SjrUFCbAJpfvLUQ3IzhF_lau6Ybzuxdw',
      fb_userid: 'https://www.facebook.com/naman.shrimal.7',
      phone: '+918306052085',
      gmail: 'naman@antaragni.in',
      ant_userid: 'naman',
      linkedin: 'https://www.linkedin.com/in/naman-jain10',
      row: 7,
    },
    {
      name: 'Ishita Agarwal',
      post: 'Head, Show Management',
      image: 'https://drive.google.com/uc?export=view&id=1OFuppg3XISITmCxKrYvaDOUPadUTvWeM',
      fb_userid: 'https://www.facebook.com/ishita.agarwal.9809/',
      phone: '+918126742270',
      gmail: 'ishitaagarwal2001@gmail.com',
      ant_userid: 'ishita',
      linkedin: 'https://www.linkedin.com/in/ishita-agarwal-1b84b4198/',
      row: 7,
    },
    {
      name: 'Ambuja Budakoti',
      post: 'Head, Design',
      image:
          'https://drive.google.com/uc?export=view&id=1HckLTOrmFUj7ZEGm-1UWBLaQaR8uNNGq',
      fb_userid: 'https://www.facebook.com/ambuja.budakoti.1',
      phone: '+919026639117',
      gmail: 'ambujabudakoti1127@gmail.com',
      ant_userid: 'Ambuja',
      linkedin: 'https://www.linkedin.com/in/ambuja-budakoti-69073919b/',
      row: 8,
    },
    {
      name: 'Tej Prakash',
      post: 'Head, Design',
      image:
          'https://drive.google.com/uc?export=view&id=13aDVtMbI1SbHU4CXCCfi5ubsxTzaSHAM',
      fb_userid: 'https://www.facebook.com/tej4826/',
      phone: '+918604566739',
      gmail: 'onlyprincetej@gmail.com',
      ant_userid: 'tej4826',
      linkedin: 'https://www.linkedin.com/in/tej-prakash-872779197/',
      row: 8,
    },
    {
      name: 'Azad Prajapat',
      post: 'Head, Web & App',
      image:
          'https://drive.google.com/uc?export=view&id=1eyB3frctFg9uT3IxNm22j5QGHgCvztYm',
      fb_userid: 'https://www.facebook.com/azad.prajapat.1',
      phone: '+918890936006',
      gmail: 'azadprajapat4@gmail.com',
      ant_userid: 'admin',
      linkedin: 'https://www.linkedin.com/in/azadprajapat',
      row: 9,
    },
  {
      name: 'Umang Chandak',
      post: 'Festival Coordinator',
      image: 'https://drive.google.com/uc?export=view&id=144iW12gEktBJjhlyy95t368dD_2KXnBO',
      fb_userid: 'https://www.facebook.com/umang.chandak23',
      phone: '+918482891976',
      gmail: 'umang.chandak12@gmail.com',
      ant_userid: 'umang',
      linkedin: 'https://www.linkedin.com/in/umang-chandak-ab8a93181',
      row: 10,
    },
    {
      name: 'Deepesh Garg',
      post: 'Festival Coordinator',
      image:
          'https://drive.google.com/uc?export=view&id=1Weo8lzPHmmaIZoOOVirIKVhmlNlqMxGJ',
      fb_userid: 'https://www.facebook.com/deepeshgarg20/',
      phone: '+919460129071',
      gmail: 'deepeshg2@gmail.com',
      ant_userid: 'deepesh',
      linkedin: 'https://www.linkedin.com/in/deepesh-garg-21579316a/',
      row: 10,
    },
  ]);
  ncontacts;

  next() {
    if (!this.busy && this.count < 9 && !this.mobile) {
      this.count++;
      this.busy = true;
      this.top -= 100;
      setTimeout(() => {
        this.busy = false;
      }, 500);
    }
  }

  back() {
    if (!this.busy && this.count > 0 && !this.mobile) {
      this.count--;
      this.busy = true;
      this.top += 100;
      setTimeout(() => {
        this.busy = false;
      }, 500);
    }
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(
    event: KeyboardEvent
  ) {
    if (event.key === 'ArrowDown') {
      this.next();
    } else if (event.key === 'ArrowUp') {
      this.back();
    }
  }

  scrollTo(i) {
    if (!this.busy) {
      this.count = i;
      this.busy = true;
      this.top = -100 * i;
      setTimeout(() => {
        this.busy = false;
      }, 500);
    }
  }

  constructor() {}

  ngOnInit(): void {
    this.ncontacts = this.contacts.pipe(
      map((res) => {
        return values(groupBy(res, 'row'));
      })
    );
  }
}
