import { Component, AfterViewInit, OnInit } from '@angular/core';
import { AnimationsComponent } from './animations';
import { NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { PlayComponent } from './play/play.component';

declare let gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [AnimationsComponent.goLeft],
})
export class AppComponent implements AfterViewInit, OnInit {
  loaded = false;
  title = "Antaragni '21";
  constructor(private router: Router, private dialog: MatDialog) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'UA-166009038-1', {
          page_path: event.urlAfterRedirects,
        });
      }
    });
  }

  ngAfterViewInit() {
    window.addEventListener('load', (ev) => {
      this.loaded = true;
    });
  }

  ngOnInit(): void {
    if (window.innerWidth < 760) {
      this.dialog.open(PlayComponent);
    }
  }
}
