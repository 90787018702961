import { Component, OnInit } from '@angular/core';
import { AresService } from '../../service/ares.service.service';


//showSlides();
var slideIndex = 0;
function showSlides() {
  var i = 0;
  var slides = document.getElementsByClassName("mySlides") as HTMLCollectionOf<HTMLElement>;
  var dots = document.getElementsByClassName("dot");
  for (i = 0; i < slides.length; i++) {
    slides[i].style.display = "none";
  }
  slideIndex++;
  if (slideIndex > slides.length) {slideIndex = 1}
  for (i = 0; i < dots.length; i++) {
    dots[i].className = dots[i].className.replace(" active", "");
  }
  slides[slideIndex-1].style.display = "block";
  dots[slideIndex-1].className += " active";
  setTimeout(showSlides, 3000); // Change image every 2 seconds
}


@Component({
  selector: 'app-merchandise',
  templateUrl: './merchandise.component.html',
  styleUrls: ['./merchandise.component.css']
})
export class MerchandiseComponent implements OnInit {
  pics =[
    { link: 'https://thumbs.dreamstime.com/b/rainbow-love-heart-background-red-wood-60045149.jpg'},
    { link: 'https://thumbs.dreamstime.com/b/rainbow-love-heart-background-red-wood-60045149.jpg'},
    { link: 'https://thumbs.dreamstime.com/b/rainbow-love-heart-background-red-wood-60045149.jpg'},
  ]
  
  isvalid = true; // whether items are available or not
  slideNum = 0;
  constructor() { }
  
  ngOnInit(): void {
      showSlides();
  }
}
