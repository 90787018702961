import { Component, OnInit } from '@angular/core';

import { AnimationsComponent } from '../../animations';
import { AresService } from '../../service/ares.service.service';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.css'],
  animations: [AnimationsComponent.mediaAnimateState],
})
export class MediaComponent implements OnInit {
  nums = [
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
  ];
  selected: number;
  items = [];
  itemLength = 0;

  isOpen = false;

  numItems = 0;
  toggle: number = innerWidth;
  condition: number;

  mobile: boolean = window.screen.width < 1023;
  bgs = ['YELLOW', 'BLUE', 'PINK', 'GREEN', 'SPA', 'ORANGE'];
  pageNum = 0;
  pageState;

  constructor(private ares: AresService) {}

  ngOnInit() {
    this.pageState = 'one';

    if (!this.mobile) {
      if (this.toggle >= 1290) {
        this.numItems = 6;
      }

      if (this.toggle < 1290) {
        this.numItems = 6;
      }

      if (this.toggle < 1137) {
        this.numItems = 6;
      }

      if (this.toggle < 890) {
        this.numItems = 4;
      }
    }

    this.ares.getcol('media').subscribe((res: any) => {
      if (res) {
        this.items = res.data;
        this.itemLength = res.data.length;
        if (this.itemLength % this.numItems === 0) {
          this.condition = this.itemLength / this.numItems - 1;
        } else {
          this.condition = this.itemLength / this.numItems - 1;
        }
        if (this.mobile) {
          this.numItems = this.itemLength;
        }
      }
    });
  }

  halfInt(num) {
    return Math.floor(num / 2) - 1;
  }

  next() {
    if (this.pageNum < this.condition) {
      this.pageState = this.pageState === 'one' ? 'two' : 'one';
      setTimeout(() => {
        this.pageNum++;
      }, 1300);
    }
    console.log(this.pageNum);
  }

  before() {
    if (this.pageNum !== 0) {
      this.pageState = this.pageState === 'one' ? 'two' : 'one';
      setTimeout(() => {
        this.pageNum--;
      }, 1300);
    }
  }
}
