import { Component, OnInit } from '@angular/core';
import { AresService } from '../../service/ares.service.service';
@Component({
  selector: 'app-enquiry',
  templateUrl: './enquiry.component.html',
  styleUrls: ['./enquiry.component.css'],
})
export class EnquiryComponent implements OnInit {
  contacts=[
    // {
    //   name: 'Ashutosh Bajpai',
    //   post: 'Manager, Hospitality & Transport',
    //   number: '+91 9140608926',
    //   email: 'ashubajpai54@gmail.com',
    //   fblink:'',
    //   linkedin:''
    // },
    // {
    //   name: 'Dipak Miglani',
    //   post: 'Manager, Hospitality & Transport',
    //   number: '+91 9582248438',
    //   email: 'dipakmiglani5@gmail.com',
    //   fblink:'',
    //   linkedin:''
    // },
    // {
    //   name: 'Bhavan Soni',
    //   post: 'Manager, Hospitality & Transport',
    //   number: '+91 7688858123',
    //   email: 'bhavansoni2000@gmail.com',
    //   fblink:'',
    //   linkedin:''
    // },
    {
      name: 'Shobhit Singh',
      post: 'Organizer, Hospitality & Transport',
      number: '8743943887',
      email: 'singhshobhit304@gmail.com',
      fblink:'',
      linkedin:'',
      insta:'https://www.instagram.com/shobhit_singh_801/'
    },
    {
      name: 'Mayank Sarwa',
      post: 'Organizer, Hospitality & Transport',
      number: '9079035719',
      email: 'sarwamayank@gmail.com',
      fblink:'',
      linkedin:'',
      insta:'https://www.instagram.com/ms_sarwa_/'
    },

  ]
  //contacts: Array<any>;
  constructor() {}

  ngOnInit(): void {
   // this.ares
     // .getcol('hospi_team')
      //.subscribe((res: any) => (this.contacts = res.data));
  }
}
