<button class="scroller" [style.opacity]="count > 0 && showContent? '1' : '0'" (click)="count == sections.length  ? goToTop(): next()" [ngStyle]="{'transform': count == sections.length ? 'rotateZ(180deg': ''}"><mat-icon>arrow_downward</mat-icon></button>

<div class="wrapper" appMouseWheel (mouseWheelUp)="back()" (mouseWheelDown)="next()">
    <div class="sections" fxLayout="column" fxLayoutAlign="start center" [ngStyle]="{'top': top + 'vh'}" id="sections">
        <div class="section welcome" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1vh">
            <button mat-button class="scroll" (click)="next()"><mat-icon style="margin-right: 2px;">arrow_downward</mat-icon>Scroll Down</button>
        </div>
        <div *ngFor="let section of sections; let i = index" class="section" [ngStyle]="{'background-image':'url(' + section.background + ')'}" [id]="'section' + i">
            <div class="text-overlay" [id]="'overlay' + i" [style.left]=" (i%2) ? '50%': '0%'" fxLayout="column" fxLayoutAlign="center start">
                <div class="header" [ngStyle]="{'opacity' : showContent ? '1': '0'}">
                    <h2 [class.right]="i%2" [class.left]="!(i%2)" [ngStyle]="{'transform': (i === count-1) ? 'translateX(0px)': (i%2)? 'translateX(-60px)' : 'translateX(60px)'}">{{section.heading}}</h2>
                </div>
            </div>
            <div class="content-half" [style.left]=" (i%2) ? '50%': '0%'" fxLayout="column">
                <div class="content-container" [ngStyle]="{'opacity' : showContent ? '1': '0'}">
                    <div class="content" [class.left3]="i%2" [class.right3]="!(i%2)" fxLayout="column" fxLayoutGap="40px" fxLayoutGap.xs="20px" [dir]="!(i%2)? 'rtl': 'ltr'">
                        <div dir="ltr" [style.text-align]="i%2 ? 'left': 'right'" class="desc" [innerHTML]="section.description"></div>
                        <button mat-raised-button class="explore" (click)="go(i)">Explore</button>
                    </div>
                </div>
            </div>
            <div [ngStyle]="{'opacity' : showContent ? '1': '0'}" class="text" fxLayout="column" fxLayoutAlign="center start" [style.left]="!(i%2) ? '50%': '0%'">
                <span [innerHTML]="section.heading" [class.rightc]="i%2" [class.leftc]="!(i%2)" [ngStyle]="{'transform': (i === count-1) ? 'translateX(0px)': (i%2)? 'translateX(-60px)' : 'translateX(60px)'}"></span>
            </div>
        </div>
    </div>
</div>
