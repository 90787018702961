<div class= "merch-block">
<div class="dummy">

    <div class="heading">Merchandise Antaragni</div>
   <!--<div class = "container"> 
    <div class="row">
        <div *ngFor="let pic of pics">
            <div class="column">
                <img src="{{pic.link}}" alt="Snow" style="width:100%">
              </div>
        </div>
       
       
    </div>
   </div> -->
   <!-- Slideshow container -->
  <div class="row" >
    <div class="column">
    <div class = "box1">
      <div class="slideshow-container " style="width: 320px;" >
     
         <!-- Full-width images with number and caption text -->
         <div class="mySlides fade">  
           <img src="/assets/merchandise/1.png" style="width:100%; padding-top: 20%;">
            <div class="text"></div>
         </div>
       
         <div class="mySlides fade"> 
            <img src="/assets/merchandise/2.png" style="width:100%; padding-top: 20%;">
            <div class="text"></div>
         </div>
       
         <div class="mySlides fade"> 
            <img src="/assets/merchandise/3.png" style="width:100%; padding-top: 20%;">
 
           <div class="text"></div>
         </div>
     
         <div class="mySlides fade"> 
              <img src="/assets/merchandise/4.png" style="width:100%; padding-top: 20%;">
              <div class="text"></div>
         </div>
       
         <!-- Next and previous buttons -->
     
       </div>
       <br>
       
     </div>
           <!-- The dots/circles -->
       <div style="text-align:center">
         <span class="dot" onclick="currentSlide(1)"></span>
         <span class="dot" onclick="currentSlide(2)"></span>
         <span class="dot" onclick="currentSlide(3)"></span>
         <span class="dot" onclick="currentSlide(4)"></span>
       </div>
     
    <div class="button-section">
      <div class ="but-allign" style="padding-top: 10%;">
        <div *ngIf="isvalid==true">
            <button class="button active-button"  onclick="window.open('https://www.onlinesbi.com/sbicollect/icollecthome.htm?corpID=314456','_blank');">Order Now</button>
        </div>
        <div *ngIf="!isvalid==true">
            <button [disabled]="true" class="button inactive-button" onclick="location.href='https://google.com';">Sorry All Units Sold</button>
        </div>
      </div>
      <!-- <div class ="but-allign" style="padding-top: 10px;">
            <button class="button active-button"  onclick="window.open('/assets/merchandise/1.png','_blank');">Payment Procedure</button>
       
    </div> -->

      </div>
       </div>
       <div class ="column">
         
        <div style="padding-left:5%;color: white;padding-bottom: 10px;font-weight: 500;font-family: MTT;font-size: 30px;">Legacy Hoodie</div>
        <div style="padding-left:5%;color: white;padding-bottom: 10px;font-family: MTT;font-size: 20px;">Official Merchandise</div>
        <div style="padding-left:5%;color: white;padding-bottom: 10px;color: white; font-family: MTT;font-size: 20px;font-weight: bold;">Price: ₹550 + 50 delivery(if applicable)</div>
        
       <div class="instructions-box " style="padding-bottom: 50px;">
        <h3 style="padding-left:5%;color: white;font-weight: 500;font-family: MTT;font-size: 30px;">Instructions</h3>
   
    
        <div class="instructions">
        <ol style="font-family: MTT;font-weight: normal;color: white;">
            <li >Payment will be done by SBI i-collect. Click on order now to direct to SBI i-collect.</li>
            <li> Agree to the terms and conditions and proceed further</li>
            <li> Select “Antaragni, IIT Kanpur” in the “Select Payment Category” dropdown menu.</li>
             <li>Enter your personal details and select your size.</li>
             <li>Leave the roll number field blank if you are not a student at IIT Kanpur.</li>
             <li>If you opt for “Home Delivery” then make sure to select Rs 600 as the amount you will
              pay. If opting for delivery inside IIT Kanpur then select Rs 550 as the amount in the
              dropdown menu.</li>
             <li>Fill your entire address including Pin Code if opting for “Home Delivery”.</li>
             <li>Proceed to the next page and make sure your details are filled in correctly.</li>
             <li>Proceed to make payment and take a screenshot of the receipt.</li>
        </ol>
        <table style="font-family: MTT;font-weight: normal;color: white;">
          <tr>
            <th>Size</th>
            <th>Chest</th>
            <th>Brand Size</th>
            <th>Shoulder</th>
            <th>Length</th>
            <th>Sleeve length</th>
            <th>Waist</th> 
          </tr>
          <tr>
            <td>S</td>
            <td>38</td>
            <td>S</td>
            <td>16</td>
            <td>27</td>
            <td>23.5</td>
            <td>34</td>

          </tr>
          <tr>
            <td>M</td>
            <td>40</td>
            <td>M</td>
            <td>17</td>
            <td>28</td>
            <td>24.5</td>
            <td>36</td>

          </tr>
          <tr>
            <td>L</td>
            <td>42</td>
            <td>L</td>
            <td>18</td>
            <td>29</td>
            <td>25.5</td>
            <td>38</td>

          </tr>
          <tr>
            <td>XL</td>
            <td>44</td>
            <td>XL</td>
            <td>19</td>
            <td>30</td>
            <td>26.5</td>
            <td>40</td>

          </tr>
          <tr>
            <td>XXL</td>
            <td>46</td>
            <td>XXL</td>
            <td>20</td>
            <td>31</td>
            <td>27.5</td>
            <td>42</td>

          </tr>
          <tr></tr>
           
        </table>
    </div>
  </div>
    </div>
  </div>
   
</div>
    

<div onclick="window.open('https://www.printexco.com/','_blank')">
   
  <img src="/assets/merchandise/vendor.jpeg" style="width:50px; height: 50px;position: absolute; right: 5%; bottom: 10px;">        
</div>
</div>