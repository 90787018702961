import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-cross',
  templateUrl: './cross.component.html',
  styleUrls: ['./cross.component.css']
})
export class CrossComponent implements OnInit {

  constructor(private matDialog: MatDialog) { }

  closeAll() {
    this.matDialog.closeAll();
  }

  ngOnInit(): void {
  }

}
