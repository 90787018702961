import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LandingComponent } from './components/landing/landing.component';
import { MouseWheelDirective } from './directives/mouse-wheel.directive';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AboutComponent } from './components/about/about.component';
//import { LineupComponent } from './components/lineup/lineup.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { ContactComponent } from './components/contact/contact.component';
import { SponsorsComponent } from './components/sponsors/sponsors.component';
import { AftermovieComponent } from './components/aftermovie/aftermovie.component';
import { BrochureComponent } from './components/brochure/brochure.component';
import { MenuComponent } from './components/menu/menu.component';
import { StarAttractionComponent } from './components/star-attraction/star-attraction.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModernizrService } from './service/modernizer.service';
import { AssociateComponent } from './components/associate/associate.component';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { FormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { EnquiryComponent } from './components/enquiry/enquiry.component';
import { MediaComponent } from './components/media/media.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CrossComponent } from './components/cross/cross.component';
import { CommonModule } from '@angular/common';
import { ScheduleComponent } from './components/schedule/schedule.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { PlayComponent } from './play/play.component';
import { MerchandiseComponent } from './components/merchandise/merchandise.component';
import { CaComponent } from './components/Campus Ambassadors/ca/ca.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    MouseWheelDirective,
    AboutComponent,
    //LineupComponent,
    ContactsComponent,
    ContactComponent,
    SponsorsComponent,
    AftermovieComponent,
    BrochureComponent,
    MenuComponent,
    AssociateComponent,
    EnquiryComponent,
    MediaComponent,
    CrossComponent,
    StarAttractionComponent,
    ScheduleComponent,
    PlayComponent,
    MerchandiseComponent,
    CaComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    BrowserAnimationsModule,
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatCardModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatDividerModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [ModernizrService, LandingComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
