import { Component, OnInit } from '@angular/core';
import { AresService } from 'src/app/service/ares.service.service';
interface Food {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-star-attraction',
  templateUrl: './star-attraction.component.html',
  styleUrls: ['./star-attraction.component.css'],
})
export class StarAttractionComponent implements OnInit {
  categories = new Set();
  attraction: Array<any>;
  constructor(private ares: AresService) {}

  ngOnInit(): void {
    this.fetchData();
  }

  fetchData(): void {
    this.ares.getcol('star_attractions').subscribe((res: any) => {
      this.attraction = res.data;
      this.attraction.forEach((e) => this.categories.add(e.category));
    });
  }

  getCards(cat: string): Array<any> {
    return this.attraction.filter(e => e.category === cat);
  }
}
