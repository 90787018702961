<div class="contact" fxLayout="column" fxLayoutGap="0.5vh" fxLayoutAlign="start center">
    <div class="upper" fxLayout="column" fxLayoutAlign="start center">
        <div class="info" fxLayout="column" fxLayoutGap="15px" fxLayoutGap.xs="10px">
            <div class="no" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="2px">
                <mat-icon>phone</mat-icon>
                <div>{{ contact.phone }}</div>
            </div>
            <a [href]="'mailto:' + contact.ant_userid + '@antaragni.in'" class="mail" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
                <mat-icon>email</mat-icon>
                <div [innerHTML]="contact.ant_userid + '@antaragni.in'"></div>
            </a>
            <div class="social" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px">
                <a [href]="contact.fb_userid" target="_blank">
                    <i class="fa fa-facebook-square" aria-hidden="true"></i>
                </a>
                <a [href]="'mailto:' + contact.gmail"><i class="fa fa-google" aria-hidden="true"></i>
                </a>
                <a [href]="contact.linkedin" target="_blank"><i class="fa fa-linkedin-square" aria-hidden="true"></i>
                </a>
            </div>
        </div>
        <div class="image">
            <img [src]="contact.image">
        </div>
    </div>
    <div class="stat" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="2px">
        <h3 [innerHtml]="contact.name" class="name"></h3>
        <div *ngIf="contact.post" [innerHtml]="contact.post" class="post"></div>
    </div>
</div>