import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ModernizrService } from '../../service/modernizer.service';

@Component({
  selector: 'app-brochure',
  templateUrl: './brochure.component.html',
  styleUrls: ['./brochure.component.css'],
})
export class BrochureComponent implements OnInit {
  left = 0;
  page: string = null;
  mobile = window.innerWidth < 540;
  pages = [];

  backgroundImage;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modern: ModernizrService
  ) {
    for (let i = 1; i <= 46; i++) {
      if (i < 10) {
        this.pages.push(i);
      } else {
        this.pages.push(i);
      }
    }
  }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.page = params.id;
      if (this.page.length < 2) {
        this.page = this.page;
      } else if (this.page.length < 3) {
        this.page = this.page;
      }
      this.backgroundImage = this.modern.webpSupport.then((res) => {
        if (res) {
          return 'url(/assets/brochure/' + this.page + '.webp)';
        } else {
          return 'url(/assets/brochure/' + this.page + '.png)';
        }
      });
      if (this.mobile) {
        this.left = -33 * (parseInt(this.page, 10) - 1) + 150;
        if (this.left > 0) {
          this.left = 0;
        }
        if (this.left < -1050) {
          this.left = -1544 + window.innerWidth;
        }
      }
    });
  }

  before() {
    if (this.page !== '1') {
      this.router.navigate(['brochure', parseInt(this.page, 10) - 1]);
      if (this.mobile) {
        this.left = -33 * (parseInt(this.page, 10) - 1) + 150;
        if (this.left > 0) {
          this.left = 0;
        }
        if (this.left < -1000) {
          this.left = -1544 + window.innerWidth;
        }
      }
    }
  }

  after() {
    if (this.page !== '46') {
      this.router.navigate(['brochure', parseInt(this.page, 10) + 1]);
      if (this.mobile) {
        this.left = -33 * (parseInt(this.page, 10) - 1) + 150;
        if (this.left > 0) {
          this.left = 0;
        }
        if (this.left < -1000) {
          this.left = -1544 + window.innerWidth;
        }
      }
    }
  }

  onKey(event: KeyboardEvent) {
    if (event.keyCode === 37 && this.page !== '1') {
      this.before();
    }
    if (event.keyCode === 39 && this.page !== '46') {
      this.after();
    }
  }
}
