<div class="big">
  <!--h1 class="heading"><span class="head">Thank</span> You</h1-->
  <div class="wrapper" [ngStyle]="{ left: top + 'vw' }" fxLayout="row" fxLayout.xs="column"> 
 <div class="cards" *ngFor="let row of ncontacts | async; let i = index" [id]="'block' + i" fxLayout="row" fxLayoutAlign="center center">
  <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="5vw" fxLayoutGap.xs="0">     
  <app-contact *ngFor="let contact of row" [contact]="contact" class="contact"></app-contact>    
  </div> -->
  </div> -->
  </div> -->
 <div *ngIf="!mobile" class="mapping" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1vh">     
 <div class="rule"></div>    
 <div fxLayout="column" fxLayoutGap="5vh">     
  <div *ngFor="let post of mapping; let i = index">    
   <div (click)="scrollTo(i)" class="post" [class.ispe]="count === i">     
  {{ post }}     
  </div>     
   </div> 
  </div>     
  </div>     
</div>
