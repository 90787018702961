import { Component, OnInit } from '@angular/core';
import { AnimationsComponent } from '../../animations';
import { MatDialog } from '@angular/material/dialog';
import { EnquiryComponent } from '../enquiry/enquiry.component';
import { MerchandiseComponent } from '../merchandise/merchandise.component';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
  animations: [AnimationsComponent.menuOpen],
})
export class MenuComponent implements OnInit {
  isOpen = false;
  social = [
    {
      name: 'fa fa-facebook-square',
      url: 'https://www.facebook.com/antaragni.iitk/',
    },
    {
      name: 'fa fa-youtube',
      url: 'https://www.youtube.com/user/antaragniiitkanpur',
    },
    {
      name: 'fa fa-instagram',
      url: 'https://www.instagram.com/antaragni.iitkanpur/',
    },
    { name: 'fa fa-twitter', url: 'https://www.twitter.com/antaragni' },
  ];

  links = [
    { name: 'Home', id: '/' },
    { name: 'About Us', id: 'about' },
    { name: 'Brochure', id: 'brochure' },
    //{ name: 'Schedule', id: 'schedule' },
    {name: 'Influencers', id: 'influencers'},
    
    {name: 'Campus Ambassadors', id: 'ca'},
    { name: 'Past Sponsors', id: 'sponsors' },
    //{ name: 'Past Lineup', id: 'lineup' },
    //{ name: 'Star Attractions', id: 'star-attraction' },
    { name: 'Media', id: 'media' },
    { name: 'Aftermovie', id: 'aftermovie' },
    { name: 'The Team', id: 'team' },
    { name: 'Associate With Us', id: 'associate' },
  ];

  items = [
    { name: 'Enquiry', id: '' },
    { name: 'Merchandise', id: 'merchandise'},
    { name: 'Roadtrips', id: ''},
    { name: 'Events & Competition', id: ''},
 
    {name : 'ca',id:''}
  ];


  toggle() {
    this.isOpen = !this.isOpen;
    document.getElementById('logo').style.opacity = '1';
  }

  go(i) {
    this.toggle();
    if (i !== 0) {
      document.getElementById('logo').style.opacity = '1';
    }
  }

  constructor(public md: MatDialog) {}

  queries() {
    this.toggle();
    this.md.open(EnquiryComponent, {
      panelClass: 'black-overlay',
    });
  }

  merch() {
    this.toggle();
    this.md.open(MerchandiseComponent, {
      panelClass: 'black-overlay',
    });
  }
  roadtrips():void{
    this.toggle();
    window.location.href = 'https://roadtrips.antaragni.in/';
  }
  events():void{
    this.toggle();
    window.location.href = 'https://events.antaragni.in/';
  }
  ngOnInit(): void {}
}
