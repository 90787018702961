<mat-sidenav-container class="_sidenav" [class.mat-sidenav-container]="isOpen">
  <mat-sidenav
    #sidenav
    class="_side"
    mode="over"
    position="end"
    [(opened)]="isOpen"
    fxLayout="column"
    fxLayoutAlign="center"
  >
    <div class="sidenav_body">
      <div class="_date">{{ items[numItems * pageNum + selected]?.date }}</div>
      <div class="_question">
        {{ items[numItems * pageNum + selected]?.content }}
      </div>
      <div
        class="_answer"
        [innerHTML]="items[numItems * pageNum + selected]?.contents?.content"
      ></div>
      <div class="know_more">
        To know more,
        <a [href]="items[selected]?.link" target="_blank">click here</a>!
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content *ngIf="items.length">
    <div
      class="grid"
      fxLayout="column"
      fxLayoutAlign="center start"
      fxLayoutAlign.xs="center center"
    >
      <div class="overlay"></div>
      <h1 class="heading">Media</h1>
      <div class="body" fxLayout fxLayoutGap="15px">
        <div
          class="arrow-left"
          fxLayout="column"
          fxLayoutAlign="center center"
          (click)="before()"
          *ngIf="!mobile"
        >
          <img
            src="https://la28.org/assets/images/pages/media/arrow.png"
            alt=""
          />
        </div>
        <div
          class="items"
          *ngIf="items"
          [ngStyle]="{ width: '80vw' }"
          fxLayout="row wrap"
          fxLayoutAlign="space-between center"
          fxLayout.xs="column"
          [@gridAnimateState]="pageState"
        >
          <div
            class="item"
            [ngStyle]="{
              width: !mobile ? '20vw' : '80vw',
              'background-image':
                'url(/assets/images/media/' + bgs[i % 6] + '.png)',
              'margin-right':
                i == halfInt(numItems) || i == numItems - 1 ? '0' : '4vw'
            }"
            *ngFor="let item of nums.slice(0, numItems); let i = index"
            fxLayout="column"
            fxLayoutAlign="start start"
            fxLayoutGap="10px"
            (click)="sidenav.toggle(); isOpen = true; selected = i"
          >
            <div
              class="top-wrap"
              fxLayoutAlign="start center"
              fxLayoutGap="7vw"
            >
              <!-- <div class="something">{{items[numItems*pageNum+i]?.content}}</div> -->
              <img
                [src]="
                  '/assets/images/media/' +
                  items[numItems * pageNum + i]?.content
                    .split(' ')[0]
                    .toLowerCase() +
                  '.png'
                "
                class="something"
                alt=""
              />
              <div class="date">{{ items[numItems * pageNum + i]?.date }}</div>
            </div>
            <div
              class="content"
              [innerHTML]="items[numItems * pageNum + i]?.contents?.content"
            ></div>
          </div>
        </div>
        <div
          class="arrow-right"
          fxLayout="column"
          fxLayoutAlign="center center"
          (click)="next()"
          *ngIf="!mobile"
        >
          <img
            src="https://la28.org/assets/images/pages/media/arrow-right.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<div class="crossed" fxLayout="row" [class.visible]="!isOpen">
  <div class="closeme" (click)="sidenav.close()" fxLayoutAlign="center center">
    X
  </div>
</div>
