import { animate, trigger, state, style, transition, query, stagger, keyframes } from '@angular/animations';

export class AnimationsComponent {
  public static menuOpen =
    trigger('menuOpen', [
      state('open', style({ opacity: 1, transform: 'translateX(0)'})),
      state('close', style({opacity: 0, transform: 'translateX(-80px)'})),
      transition('open => close', animate('0.3s ease-out')),
      transition('close => open', animate('1s  {{delay}}ms ease-out'), {params: { delay : 0}}),
    ]);

    public static goLeft =
      trigger('loader', [
        transition(':leave', [
          animate('1s cubic-bezier(.165, .84, .44, 1)', style({transform: 'translateX(-100vw)' }))
        ])
    ]);

  public static mediaAnimateState =
    trigger('gridAnimateState', [
      state('one', style({
        transform: 'translate3d(0,0,0)',
        opacity: 1,
      })),
      state('two', style({
        transform: 'translate3d(0,0,0)',
        opacity: 1,
      })),
      transition('two <=> one', [
        query('.item', stagger('60ms', [
          animate(1700, keyframes([
            style({
              transform: 'translate3d(-20%,0,0)',
              opacity: 0,
              offset: 0.1,
            }),
            style({
              transform: 'translate3d(20%,0,0)',
              opacity: 0,
              offset: 0.8,
            }),
            style({
              transform: 'translate3d(0,0,0)',
              opacity: 1,
              'animation-timing-function': 'cubic-bezier(0.445, 0.05, 0.55, 0.95)',
              offset: 1,
            }),
          ]))]), { optional: true })]),

  ]);
}
