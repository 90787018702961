<div class="chal" [style.width]="isOpen ? '100vw' : '0'">
  <div
    class="menu"
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="4vh"
  >
    <div fxLayout="column" class="menuItems1" fxLayoutGap="2vh">
      <div
        class="text"
        *ngFor="let link of links; let j = index"
        [routerLink]="link.id"
        (click)="toggle()"
        [@menuOpen]="{
          value: isOpen ? 'open' : 'close',
          params: { delay: j * 60 }
        }"
      >
        {{ link.name }}
      </div>
    </div>

    <div
      class="footer"
      fxLayout="row"
      fxLayoutGap="1vw"
      fxLayout.xs="column"
      fxLayoutAlign="start center"
      fxLayoutAlign.xs="center start"
      fxLayoutGap.xs="6vh"
    >
      <div class="inner" fxLayout="row wrap" fxLayoutGap="2vw">
        <div
          *ngFor="let item of items"
          class="innerItems"
          [routerLink]="item.id"
        >
          <div *ngIf="item.name === 'Enquiry'" (click)="queries()">
            {{ item.name }}
          </div>

        
          <div *ngIf="item.name === 'Merchandise'" [routerLink]="item.id"(click)="toggle()">
            {{ item.name }}
          </div>

          <div *ngIf="item.name === 'Roadtrips'" (click)="roadtrips()" >
              {{item.name}}
          </div>
          <div *ngIf="item.name === 'Events & Competition'" (click)="events()" >
            {{item.name}}
        </div>
       
        </div>
      </div>
      
      <div
        class="social"
        fxLayout="row"
        fxLayoutGap="1vw"
        fxLayout="start center"
        fxLayoutGap="2vw"
        fxLayoutGap.xs="5vw"
        [@menuOpen]="isOpen ? 'open' : 'close'"
      >
        <div
          class="round"
          *ngFor="let i of social"
          fxLayout="row"
          fxLayoutAlign="center center"
        >
          <a [href]="i.url" target="_blank" class="icon" rel="noopener">
            <i [class]="i.name"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="ham"
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayoutGap="8px"
  [class.isOpen]="isOpen"
  (click)="toggle()"
>
  <span></span>
  <span></span>
  <span></span>
</div>
